'use strict';

//This is a copy of the legacy amedia-image. brick-teaser is using this in addition to brick-image

const resolution = window.devicePixelRatio
  ? Math.min(2, window.devicePixelRatio)
  : 1;

let connectionSpeed = null;
const downloads = [];

function registerResource(url) {
  if (window.PerformanceResourceTiming) {
    let resource = window.performance.getEntriesByName(url);
    if (!resource[0]) {
      return;
    }
    resource = resource[0];

    if (resource) {
      const size = resource.encodedBodySize;
      const duration = resource.duration / 1000;
      if (size < 5000 || duration < 0.1) {
        // Small image, cache or not slow connection. No need to degrade;
        return;
      }
      const bits = size * 8;
      const bps = bits / duration;
      const kbps = bps / 1024;
      const mbps = kbps / 1024;

      downloads.push(mbps);
      if (downloads.length > 3) {
        connectionSpeed = downloads.reduce((a, b) => a + b) / downloads.length;
      }
    }
  }
}

const formatTime = () => {
  const tmpDate = new Date();

  const time = tmpDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${tmpDate.getDate()}-${
    tmpDate.getMonth() + 1
  }-${tmpDate.getFullYear()}-${time}`;
};

export class AmediaImageClient extends HTMLElement {
  attachedCallback() {
    this.init();
  }

  connectedCallback() {
    this.init();
  }

  init() {
    this.srcset = JSON.parse(this.getAttribute('srcset'));
    this.srcsetKeys = Object.keys(this.srcset)
      .map((key) => parseInt(key, 10))
      .sort((a, b) => a - b);

    this.threshold = 1000;
    this.type = this.getAttribute('type');
    document.dispatchEvent(
      new CustomEvent('amedia:registerLazyLoad', {
        detail: this,
      })
    );
  }

  addFullImage(width) {
    const parent = this.parentNode;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    this.rendered = true;
    const placeholder = /TIMESTAMP/gi;

    let srckey = this.srcsetKeys?.filter((w) => w <= width && w > 10).pop();
    if (!srckey) {
      srckey = this.srcsetKeys.pop();
    }
    let src = this.srcset[srckey];
    src = this.type === 'webcam' ? src.replace(placeholder, formatTime()) : src;
    src = /^http/.test(src) ? src : `https:${src}`;

    const img = new Image();
    function reveal() {
      registerResource(src);
      img.classList.add('amedia-img-full');
      if (parent.contains(_this)) {
        parent.insertBefore(img, _this);
        parent.removeChild(_this);
      }
    }
    img.crossOrigin = 'anonymous';
    img.src = src;
    img.alt = '';

    if (img.complete) {
      requestAnimationFrame(reveal);
    } else {
      img.addEventListener('load', () => requestAnimationFrame(reveal));
    }
  }

  render() {
    const aoiWidth = this.getAttribute('aoiwidth');

    const currentResoluion =
      !connectionSpeed || connectionSpeed > 1 ? resolution : 1;

    let width =
      (aoiWidth
        ? this.parentNode.offsetWidth / (parseInt(aoiWidth, 10) / 100)
        : this.parentNode.offsetWidth) * currentResoluion;

    if (connectionSpeed && connectionSpeed < 0.1) {
      width /= 2;
    }

    this.addFullImage(width);
  }
}

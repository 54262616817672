'use strict';

//It loooks like this component os only used by AmediaImageServer, that is only used by Scorponok

export class AmediaImageBase extends HTMLElement {
  init() {
    this.type = this.getAttribute('type');
    this.alt = this.getAttribute('alt');
    this.imagetype = this.getAttribute('imagetype');
    this.aoiWidth = this.getAttribute('aoiwidth');
    this.srcset = JSON.parse(this.getAttribute('srcset'));
    this.srcsetKeys = Object.keys(this.srcset)
      .map((key) => parseInt(key, 10))
      .sort((a, b) => a - b);
    this.threshold = 1000;
  }

  getImage(src) {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.alt = this.alt || '';
    img.src = src;
    return img;
  }

  getWidth() {
    const width = this.aoiWidth
      ? this.parentNode.offsetWidth / (parseInt(this.aoiWidth, 10) / 100)
      : this.parentNode.offsetWidth;

    return width;
  }
}

'use strict';

import { AmediaImageBase } from './amedia-img-base.js';

//This component is used only in Scorponok. Delete when Scorponok is archived

export class AmediaImageServer extends AmediaImageBase {
  attachedCallback() {
    this.init();
  }

  connectedCallback() {
    this.init();
  }

  getSrc(width) {
    const imgKey = Object.keys(this.srcset)
      .map((key) => parseInt(key, 10))
      .sort((a, b) => b - a)
      .filter((w) => w <= width)
      .shift();

    return this.srcset[imgKey];
  }

  addImage() {
    if (!this.srcset) {
      return Promise.reject('Missing images');
    }

    const width = this.getWidth();
    const url = this.getSrc(width);
    const img = this.getImage(url);
    if (!url) {
      return Promise.reject(`Could not find image for width ${width}px`);
    }

    let _resolve;
    let _reject;
    const loaded = new Promise((resolve, reject) => {
      _resolve = resolve;
      _reject = reject;
    });

    img.addEventListener('load', () => {
      const parent = this.parentNode;
      parent.insertBefore(img, this);
      parent.removeChild(this);
      _resolve();
    });
    img.addEventListener('error', () =>
      _reject(`Could not load image: ${url}`)
    );
    return loaded;
  }

  render() {
    return this.addImage();
  }
}

import {
  BrickElement,
  defineCustomElement,
  type EventListenerObject,
} from '@amedia/brick-template';

import { brickImageTemplate } from './template';
import type { BrickImageData } from './types';

@defineCustomElement({
  selector: 'brick-image-v3',
})
export class BrickImage extends BrickElement implements BrickImageData {
  dataSrc?: string;
  dataSrcsize?: string;
  dataSrcset?: string;
  dataSizes?: string;
  dataWidth?: string;
  dataHeight?: string;
  dataAltText?: string;
  dataLoading?: string;
  dataFetchPriority?: string;
  dataEncodeUri?: string;

  constructor() {
    super();
  }

  get eventListeners(): EventListenerObject[] {
    //no need to update anything as sizes and srcset already are set. Previously the src was calculated based on parent element width.
    return [];
  }

  get islazyload() {
    return this.getAttribute('data-loading') === 'lazy';
  }

  get graphicsHost() {
    const { host } = window.location;
    if (host.includes('snap0.api.no')) {
      return 'g.snap0.api.no';
    }
    return 'g.acdn.no';
  }

  static get mirroredProps() {
    return [
      'data-src',
      'data-srcsize',
      'data-srcset',
      'data-sizes',
      'data-width',
      'data-height',
      'data-alt-text',
      'data-loading',
      'data-fetch-priority',
      'data-encode-uri',
    ];
  }

  async connectedCallback() {
    this.style.setProperty('display', 'block');
    this.style.setProperty('width', 'var(--brick-image-width, 100%)');
    this.style.setProperty('height', 'var(--brick-image-height, 100%)');
    super.connectedCallback();
  }

  get getCSSVarValue() {
    return getComputedStyle(this).getPropertyValue('--brick-image-sizes');
  }

  get HTML() {
    return brickImageTemplate({
      dataSrc: this.dataSrc || '',
      dataSrcset: this.dataSrcset || '',
      dataSrcsize: this.dataSrcsize || '',
      dataSizes: this.getCSSVarValue || this.dataSizes || '',
      dataAltText: this.dataAltText || '',
      dataLoading: this.dataLoading || 'auto',
      dataFetchPriority: this.dataFetchPriority || 'auto',
      dataWidth: this.dataWidth || '',
      dataHeight: this.dataHeight || '',
      graphicsHost: this.graphicsHost,
      dataEncodeUri: this.dataEncodeUri || 'true',
    });
  }
}
